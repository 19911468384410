"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _appointmentFive = _interopRequireDefault(require("../../components/softBusinessLogistics/appointment-five.vue"));
var _footNav = _interopRequireDefault(require("../../components/foot-nav"));
var _default = exports.default = {
  components: {
    appointmentFive: _appointmentFive.default,
    footNav: _footNav.default
  },
  data: function data() {
    return {
      bannerList: [
      // { path: 'finance', url: require('../../assets/static/img/softBusinessLogistics/bussiness/banner-01.jpg') },
      {
        path: 'insurance',
        url: require("../../assets/static/img/softBusinessLogistics/insurance/banner-01.jpg")
      }],
      dealList: [{
        title: '处置方多',
        desc: '全国1000+危废处置方可提供服务'
      }, {
        title: '覆盖范围广',
        desc: '覆盖HW2-HW49类危废处置资质'
      }, {
        title: '处置需求大',
        desc: '70%的平台用户有危废处置的需求'
      }, {
        title: '危废商城',
        desc: '危废商城供需交集'
      }],
      certificateList: [{
        title: '驾驶员证',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/bussiness/06.png")
      }, {
        title: '押运员证',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/bussiness/07.png")
      }, {
        title: '安全员证',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/bussiness/08.png")
      }],
      zpList: [{
        title: '海量专业人员',
        desc: '50000名驾驶员/押运员',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/bussiness/09.png")
      }, {
        title: '服务各行企业',
        desc: '13750条招聘信息已发布',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/bussiness/10.png")
      }, {
        title: '专业认证',
        desc: '认证靠谱老司机',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/bussiness/10.png")
      }]
    };
  },
  methods: {
    toPage: function toPage(name) {
      this.$router.push({
        name: name
      });
    }
  }
};