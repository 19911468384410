"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _api = require("../../api/api");
var _login = _interopRequireDefault(require("../../components/loginreg/login"));
var _cookie = require("@/assets/static/js/utils/cookie.js");
var _sideNav = _interopRequireDefault(require("../../components/side-nav.vue"));
var _footNav = _interopRequireDefault(require("../../components/foot-nav"));
var _topNav = _interopRequireDefault(require("@/components/top-nav.vue"));
var _default = exports.default = {
  name: "home",
  components: {
    sideNav: _sideNav.default,
    footNav: _footNav.default,
    topNav: _topNav.default
  },
  data: function data() {
    return {
      list1: [{
        name: '数智',
        desc: '企业内部运营和管理数字化升级，提高效率并降低成本',
        imgUrl: require("../../assets/static/img/home/new/10.png")
      }, {
        name: '互联',
        desc: '打通企业内部与外部合作方的业务数据，实现互联互通',
        imgUrl: require("../../assets/static/img/home/new/11.png")
      }, {
        name: '协同',
        desc: '企业生态对接多服务平台，参与服务生态，合作共赢',
        imgUrl: require("../../assets/static/img/home/new/12.png")
      }, {
        name: '赋能',
        desc: '实现降本增效和业务导流，孕育更有竞争力的企业服务',
        imgUrl: require("../../assets/static/img/home/new/13.png")
      }],
      addServiceList: [{
        icon: require("../../assets/static/img/home/new/14.png"),
        title: '金融服务',
        content: '针对危化品运输行业特征及企业供销存各环节经营特点和差异化需求，深度解决行业企业资金流转的问题，对接第三方银行，携手推出了服务于企业贸易采购、运费代垫的金融产品'
      }, {
        icon: require("../../assets/static/img/home/new/15.png"),
        title: '保险服务',
        content: '通过保险，深入融合各业务场景，解决货主司机在车货匹配中存在的风险和信息不对称，为全平台业务保驾护航'
      }, {
        icon: require("../../assets/static/img/home/new/16.png"),
        title: '软件服务',
        content: '危化镖局深耕行业多年，深知传统化工物流行业效率低下之顽疾，自建了一套成熟的TMS、WMS管理系统，同时还和中国联通、化工宝等企业形成战略合作，为化工园区、生产企业、物流公司提供专业化的软件定制服务'
      }, {
        icon: require("../../assets/static/img/home/new/18.png"),
        // title: '财税服务',
        // content: '为化工物流企业提供一站式完整财税解决方案，帮助企业解决经营票务上多个痛点难点，通过合理合规流程，大幅降低企业的税负成本，争取利益最大化',
        title: '镖局查车',
        content: '基于危险品车辆自身的GPS数据，无需额外硬件，只需要车牌号即可能够实时查看并保留的危化品车辆的实时位置及轨迹'
      }]
    };
  },
  watch: {
    $route: function $route(to, from) {
      if (this.$route.path.indexOf('home') >= 0) {
        if (this.$route.query.to) {
          this.goAnchor(this.getSelector());
        }
      }
    }
  },
  mounted: function mounted() {
    if (this.$route.query.to) {
      this.goAnchor(this.getSelector());
    }
  },
  methods: {
    // 获取锚点
    getSelector: function getSelector() {
      var toIdName = this.$route.query.to;
      return toIdName;
    },
    // 锚点滚动
    goAnchor: function goAnchor(selector) {
      console.log('selector', selector);
      this.$refs["".concat(selector)].scrollIntoView({
        behavior: "smooth",
        // 平滑过渡
        block: "start" // start,center,end
      });
    }
  }
};