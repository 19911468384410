"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page"
  }, [_c("el-carousel", {
    staticClass: "height-516"
  }, _vm._l(_vm.bannerList, function (item, index) {
    return _c("el-carousel-item", {
      key: index,
      staticClass: "height-516"
    }, [_c("img", {
      staticClass: "mouse-pointer height-516 width-full",
      attrs: {
        src: item.url,
        alt: ""
      },
      on: {
        click: function click($event) {
          return _vm.toPage(item.path);
        }
      }
    })]);
  }), 1), _c("div", {
    staticClass: "cont-02"
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center"
  }, [_c("div", {
    staticClass: "flex-col-start text-left"
  }, [_c("div", {
    staticClass: "font-36 font-weight-500 color-212121"
  }, [_vm._v("镖局保险")]), _c("div", {
    staticClass: "font-16 color-555555 margin-top-20 line-height-16"
  }, [_vm._v(" 危化镖局平台旨在搭建一站式物流保险管理服务平台，为企业在物流过程中提供快速投保、助赔、风险防范和管理等全面服务。平台保险产品以货运险、 物流责任险为主，不断延伸创新各种保险产品，全方位满足客户的保险需！ ")]), _c("div", {
    staticClass: "more-btn margin-top-60",
    on: {
      click: function click($event) {
        return _vm.toPage("insurance");
      }
    }
  }, [_vm._v("了解更多")])]), _c("img", {
    staticClass: "width-580",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/bussiness/02.jpg")
    }
  })])]), _vm._m(0), _vm._m(1), _c("div", {
    staticClass: "cont-05"
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "font-36 font-weight-500 color-212121"
  }, [_vm._v("危废处置")]), _c("div", {
    staticClass: "font-18 color-555555 margin-top-30 line-height-16"
  }, [_vm._v("危化镖局主要服务有化工危废信息对接、化工固废信息对接、化工副产品信息对接、废催化剂类信息对接。平台凭借自身线上线下积累的信息资源，为企业解决信息不对称的痛点。")]), _c("div", {
    staticClass: "flex-row-sb align-item-center margin-top-60"
  }, _vm._l(_vm.dealList, function (item, index) {
    return _c("div", {
      staticClass: "deal-item flex-col-center align-item-center"
    }, [_c("div", {
      staticClass: "deal-item-header"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "deal-item-body"
    }, [_vm._v(_vm._s(item.desc))])]);
  }), 0)])]), _vm._m(2), _c("div", {
    staticClass: "cont-07"
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "font-36 font-weight-500 color-212121"
  }, [_vm._v("办证服务")]), _c("div", {
    staticClass: "font-18 color-555555 margin-top-30"
  }, [_vm._v("目前可以协助办理危险品驾驶上岗证、押运员证、安全员证等多种证件")]), _c("div", {
    staticClass: "flex-row-sb align-item-center margin-top-60"
  }, _vm._l(_vm.certificateList, function (item, index) {
    return _c("div", {
      staticClass: "certificate-item flex-col-center align-item-center"
    }, [_c("img", {
      staticClass: "certificate-img",
      attrs: {
        src: item.imgUrl
      }
    }), _c("span", {
      staticClass: "font-16 color-6E6E6E margin-top-25"
    }, [_vm._v(_vm._s(item.title))])]);
  }), 0)])]), _c("div", {
    staticClass: "cont-08"
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center"
  }, [_c("div", {
    staticClass: "flex-col-start align-item-start"
  }, [_c("div", {
    staticClass: "font-36 color-212121"
  }, [_vm._v("企业招聘")]), _c("div", {
    staticClass: "font-18 color-555555 margin-top-20"
  }, [_vm._v("协助各大企业招聘驾驶员、押运员，接受委托待招服务，汇聚人才，服务未来")]), _c("div", {
    staticClass: "flex-row-sb align-item-start margin-top-60 width-full"
  }, _vm._l(_vm.zpList, function (item, index) {
    return _c("div", {
      staticClass: "item flex-col-center align-item-center"
    }, [_c("img", {
      staticClass: "width-66",
      attrs: {
        src: item.imgUrl
      }
    }), _c("span", {
      staticClass: "font-18 color-212121 margin-top-20"
    }, [_vm._v(_vm._s(item.title))]), _c("span", {
      staticClass: "font-16 color-6E6E6E margin-top-10"
    }, [_vm._v(_vm._s(item.desc))])]);
  }), 0)]), _c("img", {
    staticClass: "width-455",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/bussiness/12.png")
    }
  })])]), _c("appointment-five", {
    attrs: {
      sourceType: "110"
    }
  }), _c("foot-nav")], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-03"
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center"
  }, [_c("img", {
    staticClass: "width-555",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/bussiness/03.jpg")
    }
  }), _c("div", {
    staticClass: "flex-col-start text-left"
  }, [_c("div", {
    staticClass: "font-36 font-weight-500 color-212121"
  }, [_vm._v("仓储租赁")]), _c("div", {
    staticClass: "font-16 color-555555 margin-top-20 line-height-16"
  }, [_vm._v(" 危化镖局平台致力于提供真正的综合物流解决方案，连接并简化客户的供应链，以仓储资源为基点和核心、延伸覆盖仓储物流化工加工，借助信息大数据优势，利用线上线下渠道，给仓储物流需求的客户提供高效的供需匹配，让使用者和资源拥有者获得更优化的资源配置，降低时间成本、使用成本。 ")]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-35"
  }, [_c("span", {
    staticClass: "text-circle"
  }, [_vm._v("01")]), _c("span", {
    staticClass: "color-365ACD font-18"
  }, [_vm._v("全国各省市均有合作仓库")])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-35"
  }, [_c("span", {
    staticClass: "text-circle"
  }, [_vm._v("02")]), _c("span", {
    staticClass: "color-365ACD font-18"
  }, [_vm._v("利用我们的数据库快速匹配资源")])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-35"
  }, [_c("span", {
    staticClass: "text-circle"
  }, [_vm._v("03")]), _c("span", {
    staticClass: "color-365ACD font-18"
  }, [_vm._v("仓储物流供应链一站式服务")])])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-04"
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center"
  }, [_c("div", {
    staticClass: "flex-col-start text-left"
  }, [_c("div", {
    staticClass: "font-36 font-weight-500 color-212121"
  }, [_vm._v("化工贸易")]), _c("div", {
    staticClass: "font-16 color-555555 margin-top-20 line-height-16"
  }, [_vm._v(" 危化镖局提供基于互联网平台的专业性贸易撮合与促进服务，为化学品采购商和供应商提供服务，平台以真实、精准、丰富为目标，建立了化工产品库、公司数据库，提供自动撮合匹配的采购通服务，可大大提高采购商找产品效率！ ")]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-35"
  }, [_c("span", {
    staticClass: "text-circle"
  }, [_vm._v("01")]), _c("span", {
    staticClass: "color-365ACD font-18"
  }, [_vm._v("砍掉中间环节，降低采购成本，间接提高企业利润")])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-35"
  }, [_c("span", {
    staticClass: "text-circle"
  }, [_vm._v("02")]), _c("span", {
    staticClass: "color-365ACD font-18"
  }, [_vm._v("筛选优质供应商，确保商品质量可靠性和供应稳定性")])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-35"
  }, [_c("span", {
    staticClass: "text-circle"
  }, [_vm._v("03")]), _c("span", {
    staticClass: "color-365ACD font-18"
  }, [_vm._v("提供产品物流仓储一站式解决方案，省心省力更省钱")])])]), _c("img", {
    staticClass: "width-640",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/bussiness/04.png")
    }
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-06"
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center"
  }, [_c("div", {
    staticClass: "flex-col-start text-left"
  }, [_c("div", {
    staticClass: "font-36 font-weight-500 color-white"
  }, [_vm._v("学习中心")]), _c("div", {
    staticClass: "font-16 color-white margin-top-20"
  }, [_vm._v(" 针对危险品运输、仓储、生产过程中从业人员，危化镖局搭建了一套完整的学习培训系统，为从业人员提供安全生产操作、标准化作业流程、出入园区培训等在线教育系统。 ")]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-35"
  }, [_c("span", {
    staticClass: "text-circle"
  }, [_vm._v("01")]), _c("span", {
    staticClass: "color-white font-18"
  }, [_vm._v("安全生产培训——各工种安全生产专业培训")])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-35"
  }, [_c("span", {
    staticClass: "text-circle"
  }, [_vm._v("02")]), _c("span", {
    staticClass: "color-white font-18"
  }, [_vm._v("标准化作业流程——专业化、规范化生产操作流程")])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-35"
  }, [_c("span", {
    staticClass: "text-circle"
  }, [_vm._v("03")]), _c("span", {
    staticClass: "color-white font-18"
  }, [_vm._v("园区培训系统——出入化工生产园区在线学习教育")])])]), _c("img", {
    staticClass: "width-710",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/bussiness/13.png")
    }
  })])]);
}];
render._withStripped = true;