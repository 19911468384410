"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _footNav = _interopRequireDefault(require("../../components/foot-nav"));
var _appointmentThr = _interopRequireDefault(require("../../components/softBusinessLogistics/appointment-thr.vue"));
var _default = exports.default = {
  components: {
    footNav: _footNav.default,
    appointmentThr: _appointmentThr.default
  },
  data: function data() {
    return {
      productList: [{
        title: '人员',
        content: '司机、押运员维护，证件管理',
        imgUrl: require("../../assets/static/img/wllm/03.png")
      }, {
        title: '资金',
        content: '应收应付，财务管理系统',
        imgUrl: require("../../assets/static/img/wllm/04.png")
      }, {
        title: '车辆',
        content: '自有车辆，合作车辆管理',
        imgUrl: require("../../assets/static/img/wllm/05.png")
      }, {
        title: '资源',
        content: '发货发车计划，空车匹配，海量货源',
        imgUrl: require("../../assets/static/img/wllm/06.png")
      }],
      bussineseList: [{
        title: '保险服务',
        content: '货运险、物流责任险、意外险等为运输保驾护航',
        imgUrl: require("../../assets/static/img/wllm/07.png")
      },
      // { title: '网络货运', content: '合法合规，资金安全，有效处理税收开票问题', imgUrl: require('../../assets/static/img/wllm/08.png') },
      {
        title: '仓储租赁',
        content: '全国范围内的危险品仓储资源，解决仓储周转问题',
        imgUrl: require("../../assets/static/img/wllm/09.png")
      }, {
        title: '危废处置',
        content: '拥有庞大的危废处置机构资源，危险品废物提供处置、运输一站式解决',
        imgUrl: require("../../assets/static/img/wllm/10.png")
      }, {
        title: '招聘求职',
        content: '协助各大企业招聘驾驶员、押运员，解决物流企业招聘难题',
        imgUrl: require("../../assets/static/img/wllm/11.png")
      }],
      myAdvantage: [{
        title: '资源优势',
        content: '依托于危化镖局平台的资源优势及联盟的优势路线互补，形成海量货源优势。每日10万条货源信息，累计货源近1亿票，路线覆盖全国各地。'
      }, {
        title: '资金优势',
        content: '依托于危化镖局平台，为联盟企业提供供应链金融服务，有效解决企业资金周转难题。订货贷、运费代垫，最高额度可达300万，可循环使用。'
      }, {
        title: '营销优势',
        content: '平台、公众号、头条号、百度为核心的18个媒体矩阵，站内百万量级曝光，站外亿万级曝光。线下全国各地市场拓展人员针对化工企业推广。'
      }],
      serviceList: [{
        imgUrl: require("../../assets/static/img/wllm/13.png"),
        desc1: '200万条车源路线',
        desc2: '寻找最佳合作'
      }, {
        imgUrl: require("../../assets/static/img/wllm/14.png"),
        desc1: '15万位司机在线',
        desc2: '更好议价'
      }, {
        imgUrl: require("../../assets/static/img/wllm/15.png"),
        desc1: '智能配载实现更好的',
        desc2: '环形干线资源'
      }, {
        imgUrl: require("../../assets/static/img/wllm/16.png"),
        desc1: '回程运力优化配置',
        desc2: '双向获利'
      }, {
        imgUrl: require("../../assets/static/img/wllm/17.png"),
        desc1: '保理、油卡等后服务',
        desc2: '统一采购'
      }, {
        imgUrl: require("../../assets/static/img/wllm/18.png"),
        desc1: '账款账期、结款结算',
        desc2: '统一优化对接'
      }, {
        imgUrl: require("../../assets/static/img/wllm/19.png"),
        desc1: '人才招聘、司机加盟、车',
        desc2: '辆加盟统一规划'
      }, {
        imgUrl: require("../../assets/static/img/wllm/20.png"),
        desc1: '智能化财务、仓储、物流',
        desc2: '软件系统降本增效'
      }],
      joinList1: [{
        imgUrl: require("../../assets/static/img/wllm/22.png"),
        title: '区域仓储资源',
        content: '在地方具备一定的仓储资源，能解决落地仓储收揽资源'
      }, {
        imgUrl: require("../../assets/static/img/wllm/23.png"),
        title: '区域开发能力',
        content: '具备一定的业务开发团队，能协同进行客户开发及同行揽收能力'
      }, {
        imgUrl: require("../../assets/static/img/wllm/24.png"),
        title: '一定的业务量',
        content: '自身具备一定的业务规模，且自身月度营收额超过百万元'
      }],
      joinList2: [{
        imgUrl: require("../../assets/static/img/wllm/25.png"),
        title: '区域落地配送资源',
        content: '自身具备一定的车源及区域配送的整合能力'
      }, {
        imgUrl: require("../../assets/static/img/wllm/26.png"),
        title: '区域管理能力',
        content: '具备多网点、多区域的综合协调管理能力'
      }, {
        imgUrl: require("../../assets/static/img/wllm/27.png"),
        title: '拥抱互联网',
        content: '理解互联网，理解智慧创新'
      }, {
        imgUrl: require("../../assets/static/img/wllm/28.png"),
        title: '有“心”之人',
        content: '积极发展的心，客户至上的心协同共赢的心'
      }]
    };
  },
  watch: {
    $route: function $route(to, from) {
      if (this.$route.path.indexOf('wllm') >= 0) {
        if (this.$route.query.to) {
          this.goAnchor(this.getSelector());
        }
      }
    }
  },
  mounted: function mounted() {
    if (this.$route.query.to) {
      this.goAnchor(this.getSelector());
    }
  },
  methods: {
    // 获取锚点
    getSelector: function getSelector() {
      var toIdName = this.$route.query.to;
      return toIdName;
    },
    // 锚点滚动
    goAnchor: function goAnchor(selector) {
      this.$refs["".concat(selector)].scrollIntoView({
        behavior: "smooth",
        // 平滑过渡
        block: "start" // start,center,end
      });
    }
  }
};