"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "detail-container"
  }, [_c("trade-banner"), _c("div", {
    staticClass: "tab"
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center margin-auto"
  }, [_c("div", {
    staticClass: "color-666666 font-22"
  }, [_vm._v("化工物流/交易大厅")]), _c("div", {
    staticClass: "height-full flex-row-start"
  }, _vm._l(_vm.tabList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "tab-item flex-row-center font-22",
      class: [index == 1 ? "active-tab-item" : ""],
      on: {
        click: function click($event) {
          return _vm.tabChange(item.url);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0)])]), _c("div", {
    staticClass: "detail-main"
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "top flex-column-left"
  }, [_c("div", {
    staticClass: "color-111111 font-36 font-weight-500"
  }, [_c("span", {
    staticClass: "ellipsis margin-right-20"
  }, [_vm._v(_vm._s(_vm.joinedStart))]), _c("img", {
    staticClass: "width-36",
    attrs: {
      src: "https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/hall/jiantou2.png",
      alt: ""
    }
  }), _c("span", {
    staticClass: "ellipsis margin-left-20"
  }, [_vm._v(_vm._s(_vm.joinedEnd))])]), _c("div", {
    staticClass: "flex-row-start margin-top-20"
  }, [_vm.detailData.vehicleType ? _c("span", {
    staticClass: "name"
  }, [_vm._v("  " + _vm._s(_vm.detailData.vehicleType) + "  ")]) : _vm._e(), _vm.detailData.vehicleLength > 0 ? _c("span", {
    staticClass: "name"
  }, [_vm._v("   " + _vm._s(_vm.detailData.vehicleLength) + "米  ")]) : _vm._e(), _vm.detailData.vehicleLoadWeight > 0 ? _c("span", {
    staticClass: "name"
  }, [_vm._v("  " + _vm._s(_vm.detailData.vehicleLoadWeight) + "吨  ")]) : _vm._e()]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-30"
  }, [_c("span", {
    staticClass: "font-18 color-555555"
  }, [_vm._v("参考价格：")]), _c("span", {
    staticClass: "font-weight-500 color-F75923 font-22"
  }, [_vm._v(_vm._s(_vm.detailData.minPrice > 0 ? _vm.detailData.minPrice + "-" + _vm.detailData.topPrice + "元" : _vm.detailData.sinPrice ? _vm.detailData.sinPrice + "元" : "暂无价格"))])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-50 font-16 color-B2B2B2"
  }, [_vm._v("发布时间:" + _vm._s(_vm.detailData.releaseTime))]), _c("div", {
    staticClass: "share"
  }, [_c("div", {
    staticClass: "qrcode flex-column-alignc"
  }, [_c("div", {
    staticClass: "qrcode-img"
  }, [_c("qriously", {
    attrs: {
      value: _vm.$root.baseUrl + "/app/cargo/ammShareMain.app?type=2&id=" + _vm.vsid + "&userId=" + _vm.userId,
      size: 114
    }
  })], 1), _c("span", {
    staticClass: "ellipsis font-16 color-B2B2B2 margin-top-15"
  }, [_vm._v("请用微信扫描分享")])]), _c("div", {
    staticClass: "flex-row-end align-item-center font-16 color-B2B2B2 margin-top-30"
  }, [_c("span", {
    staticClass: "margin-right-20"
  }, [_vm._v("还可以分享到")]), _c("share", {
    attrs: {
      config: _vm.shareConfig
    }
  })], 1)])]), _c("el-divider"), _c("div", {
    staticClass: "detail"
  }, [_c("el-row", {
    staticClass: "margin-top-25"
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "flex-row-start align-item-center"
  }, [_c("img", {
    staticClass: "width-32",
    attrs: {
      src: "http://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/hall/chufachengshiico.png",
      alt: ""
    }
  }), _c("span", {
    staticClass: "margin-left-15 font-16 color-B2B2B2"
  }, [_vm._v("出发城市：")]), _c("span", {
    staticClass: "font-16 color-212121"
  }, [_vm._v(_vm._s(_vm.detailData.vsOrigin))])])]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "flex-row-start align-item-center"
  }, [_c("img", {
    staticClass: "width-32",
    attrs: {
      src: "http://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/hall/daodachengshiico.png",
      alt: ""
    }
  }), _c("span", {
    staticClass: "margin-left-15 font-16 color-B2B2B2"
  }, [_vm._v("到达城市：")]), _c("span", {
    staticClass: "font-16 color-212121"
  }, [_vm._v(_vm._s(_vm.detailData.vsDestination))])])])], 1), _c("el-row", {
    staticClass: "margin-top-25"
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "flex-row-start align-item-center"
  }, [_c("img", {
    staticClass: "width-32",
    attrs: {
      src: "http://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/hall/zhuangcheshijian.png",
      alt: ""
    }
  }), _c("span", {
    staticClass: "margin-left-15 font-16 color-B2B2B2"
  }, [_vm._v("发车时间：")]), _c("span", {
    staticClass: "font-16 color-212121"
  }, [_vm._v(_vm._s(_vm.detailData.vsTypeCode == 10 ? "长期运力" : _vm.detailData.vsStartSetOutTime || _vm.detailData.vsEndSetOutTime ? "".concat(_vm.formatDate(new Date(_vm.detailData.vsStartSetOutTime), "MM月dd日"), "-").concat(_vm.formatDate(new Date(_vm.detailData.vsEndSetOutTime), "MM月dd日")) : "电话联系"))])])])], 1), _c("el-row", {
    staticClass: "margin-top-25"
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "flex-row-start align-item-center"
  }, [_c("img", {
    staticClass: "width-32",
    attrs: {
      src: "http://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/hall/beizhu.png",
      alt: ""
    }
  }), _c("span", {
    staticClass: "margin-left-15 font-16 color-B2B2B2"
  }, [_vm._v("备注信息：")]), _c("span", {
    staticClass: "font-16 color-212121"
  }, [_vm._v(_vm._s(_vm.detailData.vsRemark ? _vm.detailData.vsRemark : "联系时请说明在“危化镖局”看到的。提升车主信任度，更容易获得订单哦！"))])])])], 1), _c("el-button", {
    directives: [{
      name: "track-event",
      rawName: "v-track-event",
      value: {
        category: "联系车主",
        action: "点击",
        opt_label: _vm.joinedStart + _vm.joinedEnd,
        opt_value: _vm.detailData.vsid
      },
      expression: "{category:'联系车主', action:'点击', opt_label:joinedStart+joinedEnd, opt_value:detailData.vsid}"
    }],
    staticClass: "pub-btn",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.clickToCall
    }
  }, [_vm._v(" 电话联系 ")])], 1)], 1), _vm._m(0), _c("div", {
    staticClass: "rec"
  }, [_c("div", {
    staticClass: "title flex-row-alignc"
  }, [_c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _c("span", [_vm._v("相关推荐")])], 1), _c("div", {
    staticClass: "rec-list"
  }, [_c("car-list", {
    attrs: {
      sourceData: _vm.recData,
      sortConfig: _vm.sortConfig
    }
  })], 1)])]), _c("foot-nav"), _c("ask-push", {
    attrs: {
      show: _vm.showPush,
      type: 1,
      vsid: _vm.vsid
    },
    on: {
      close: function close($event) {
        _vm.showPush = false;
      }
    }
  }), _c("el-dialog", {
    staticClass: "call-dialog",
    attrs: {
      visible: _vm.showPhone,
      "show-close": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showPhone = $event;
      }
    }
  }, [_c("div", {
    staticClass: "phone"
  }, [_c("img", {
    staticClass: "width-770",
    attrs: {
      src: require("../../assets/static/img/hall/pop_kefu.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "close",
    on: {
      click: function click($event) {
        _vm.showPhone = false;
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-circle-close"
  })])]), _c("purchase-dialog", {
    attrs: {
      visible: _vm.dialogVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      },
      confirm: _vm.handleConfirm,
      cancel: _vm.handleCancel
    }
  }), _c("pop-rights", {
    attrs: {
      visible: _vm.popVisible,
      newtext: _vm.poprightsnewtext,
      text: _vm.poprightstext
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.popVisible = $event;
      },
      confirm: _vm.popConfirm,
      cancel: _vm.popCancel
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tip text-left"
  }, [_c("img", {
    staticClass: "margin-right-10",
    attrs: {
      src: require("../../assets/static/img/common/warm-FD9E31.png")
    }
  }), _c("div", [_vm._v("平台信息由用户发布，请您自行核对信息真实性，确保资质合规，平台只提供信息服务，不对其承担任何义务及连带责任！请提高防范意识，保存相关证据，警惕骗子冒充承运商及平台客服，谨防网络诈骗！")])]);
}];
render._withStripped = true;