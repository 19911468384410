"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "box"
  }, [_c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      "custom-class": "custom-dialog ",
      "close-on-click-modal": false,
      "show-close": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      close: _vm.handleClose
    }
  }, [_c("div", {
    staticClass: "closeIcon"
  }, [_c("i", {
    staticClass: "el-icon-close closeIcon",
    on: {
      click: _vm.handleClose
    }
  })]), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "text"
  }, [_vm._v("温馨提示")]), _vm.text ? _c("div", {
    staticClass: "newtext"
  }, [_vm._v(_vm._s(_vm.text))]) : _vm._e(), _vm.newtext ? _c("div", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.newtext))]) : _vm._e(), _c("div", {
    staticClass: "dialog-footer"
  }, [_c("el-button", {
    attrs: {
      type: "info"
    },
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleConfirm
    }
  }, [_vm._v("去购买")])], 1)])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;