"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "box"
  }, [_c("el-dialog", {
    attrs: {
      visible: _vm.visible,
      "custom-class": "custom-dialog ",
      "close-on-click-modal": false,
      "show-close": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      close: _vm.handleClose
    }
  }, [_c("div", {
    staticClass: "closeIcon headbg"
  }, [_c("i", {
    staticClass: "el-icon-close closeIcon",
    on: {
      click: _vm.handleClose
    }
  }), _c("div", {
    staticClass: "headbox"
  }, [_c("div", {
    staticClass: "head_text"
  }, [_vm._v("购买权益")]), _c("div", {
    staticClass: "head_title"
  }, [_vm._v("官网购买权益跟app共用，app有付费在有效期内PC官网也可以使用；反之PC官网有付费，在有效期内app也可使用")])])]), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "content_box"
  }, [_c("div", {
    staticClass: "content_head"
  }, [_c("div", {
    staticClass: "content_img"
  }, [_c("img", {
    attrs: {
      src: require("../../assets/static/img/rights/\u62E8\u6253\u7535\u8BDD.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "textbox"
  }, [_c("div", {
    staticClass: "textbox_text"
  }, [_vm._v("车源拨号增值服务")]), _c("div", {
    staticClass: "textbox_year"
  }, [_c("span", {
    staticClass: "period_year"
  }, [_vm._v("有效期30天")]), _c("span", {
    staticClass: "notSupported"
  }, [_vm._v("不支持退款")])]), _c("div", {
    staticClass: "textbox_day"
  }, [_vm._v("可在有效期内无限次使用，有利于减少打扰次数，提高对接效率")])])])]), _c("div", {
    staticClass: "ChoosePackage"
  }, [_c("div", {
    staticClass: "Package"
  }, [_vm._v("选择套餐")]), _c("div", {
    staticClass: "grid-container"
  }, [_c("div", [_vm._v("1")]), _c("div", [_vm._v("2")]), _c("div", [_vm._v("3")]), _c("div", [_vm._v("4")]), _c("div", [_vm._v("5")]), _c("div", [_vm._v("6")])])])])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;