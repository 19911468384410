"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: "PurchaseDialog",
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: ''
    },
    newtext: {
      type: String,
      required: ''
    }
  },
  methods: {
    handleClose: function handleClose() {
      this.$emit("update:visible", false); // 关闭弹窗
    },
    handleCancel: function handleCancel() {
      this.handleClose();
      this.$emit("cancel"); // 点击关闭按钮的回调
    },
    handleConfirm: function handleConfirm() {
      this.handleClose();
      this.$emit("confirm"); // 点击确认按钮的回调
    }
  }
};